import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
//import ScrollContainer from "react-indiana-drag-scroll";
import getDayOrNight from "./../helpers/getDayOrNight";
import { TimeOffsetContext } from "./../App";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment-timezone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const cityTypographyStyles = makeStyles((theme) => ({
  location: {
    textAlign: "center",
    lineHeight: "1.2",
    fontWeight: 200,
    fontSize: "0.8rem",
  },
  "@media (max-height: 640px)": {
    location: {
      fontSize: "0.5rem",
    },
  },
}));

const CityTypography = React.memo(({ city }) => {
  const classes = cityTypographyStyles();

  return (
    <Typography variant="caption" className={classes.location}>
      {city.city} <br />
      {city.country}
    </Typography>
  );
});

const closeButtonStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 2,
    color: `${theme.palette.text.primary}`,

    "&:hover": {
      color: `${theme.palette.text.light}`,
    },
  },
  icon: {
    fontSize: "1rem",
  },
}));

const CloseButton = ({ cities, setCities, index }) => {
  const classes = closeButtonStyles();

  const handleClick = () => {
    const citiesArr = cities.slice(0, index).concat(cities.slice(index + 1));
    setCities(citiesArr);
    localStorage.setItem("cities", JSON.stringify(citiesArr));
  };

  return (
    <IconButton className={classes.button} onClick={handleClick}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  );
};

const cityCardStyles = makeStyles((theme) => ({
  cityTimeContainer: {
    position: "relative",
    padding: "16px 0 24px",
    width: 152,
    zIndex: 100,
  },
  day: {
    borderBottom: `4px solid ${theme.palette.day.main}`,
  },
  night: {
    borderBottom: `4px solid ${theme.palette.night.main}`,
  },
  time: {
    fontWeight: 200,
    fontSize: "2.8rem",
  },
  timeCycle: {
    padding: "2px 0 8px",
    fontSize: "0.8rem",
  },
  "@media (max-height: 640px)": {
    cityTimeContainer: { padding: "16px 0 20px", width: 96 },
    time: {
      fontSize: "1.6rem",
    },
    timeCycle: {
      padding: "0 0 4px",
      fontSize: "0.5rem",
    },
  },
}));

function CityCard({ city, time, cities, setCities, index }) {
  const classes = cityCardStyles();
  const [showButton, setShowButton] = useState(() => false);
  const timeOffset = useContext(TimeOffsetContext);
  const timeWithOffset = new Date(time.getTime() - timeOffset);
  const preFormattedTime = moment(timeWithOffset)
    .tz(city.timeZone)
    .format("LT");

  const hoursAndMinutes = preFormattedTime.slice(
    0,
    preFormattedTime.length - 3
  );
  const ampm = preFormattedTime.slice(
    preFormattedTime.length - 2,
    preFormattedTime.length
  );
  const dayOrNight = getDayOrNight(
    timeWithOffset,
    city.coordinates[1],
    city.coordinates[0]
  );

  return (
    <Grid
      item
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={`${classes.cityTimeContainer} ${classes[dayOrNight]}`}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      {showButton && (
        <CloseButton cities={cities} setCities={setCities} index={index} />
      )}
      <Typography className={classes.time} variant="h4">
        {hoursAndMinutes}
      </Typography>
      <Typography variant="caption" className={classes.timeCycle}>
        {ampm}
      </Typography>
      <CityTypography city={city} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    zIndex: 1000,
    position: "relative",
    opacity: 0.75,
  },
  outerContainerNotScrollable: {
    backgroundColor: theme.palette.primary.main,
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
  },
  scrollable: { paddingBottom: 4 },
  notScrollable: { justifyContent: "center" },
  scrollContainer: {
    width: "100vw",
    display: "flex",
    overflow: "auto",
    whiteSpace: "nowrap",
    position: "relative",
    zIndex: 100,
    scrollbarColor: `${theme.palette.primary.light} transparent`,
    "&::-webkit-scrollbar": {
      height: 12,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.light,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",

  background: isDragging ? "#6e6d72" : "rgba(67, 66, 71, 1)",
  boxShadow: isDragging
    ? "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
    : "none",
  display: "inline-block",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const CityTimeBar = ({ cities, time, setCities }) => {
  const classes = useStyles();

  const isLarge = useMediaQuery("(max-height: 640px)");
  const showScrollBar = isLarge
    ? window.innerWidth < cities.length * 96
    : window.innerWidth < cities.length * 152;

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newCities = Array.from(cities);
    const [reorderedItem] = newCities.splice(result.source.index, 1);
    newCities.splice(result.destination.index, 0, reorderedItem);

    setCities(newCities);
    localStorage.setItem("cities", JSON.stringify(newCities));
  };

  return (
    <div
      className={`${classes.outerContainer} ${
        !showScrollBar && classes.outerContainerNotScrollable
      }`}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${classes.scrollContainer} ${
                showScrollBar ? classes.scrollable : classes.notScrollable
              }`}
            >
              <div className={classes.bar}>
                {cities.map((city, index) => (
                  <Draggable
                    key={city.city}
                    draggableId={city.city}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <CityCard
                          city={city}
                          time={time}
                          key={`${city.city} + ${index}`}
                          index={index}
                          cities={cities}
                          setCities={setCities}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default React.memo(CityTimeBar);
