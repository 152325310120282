import React, { useState, createContext } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";

// Hooks
import useTime from "./hooks/useTime";

// Components
import SearchBar from "./components/SearchBar";
import Map from "./components/Map";
import CityTimeBar from "./components/CityTimeBar";
import SunLight from "./components/SunLight";
import TimeLine from "./components/TimeLine";
import CityTimeBoard from "./components/CityTimeBoard";

export const TimeOffsetContext = createContext();

function App() {
  // Load the list of cities from localStorage, otherwise,
  // we're loading them from our base using the variable `initialCities` below.
  const [cities, setCities] = useState(() =>
    localStorage.getItem("cities")
      ? JSON.parse(localStorage.getItem("cities"))
      : initialCities
  );

  // If the user scrolls, we adjust this offset.
  const [timeOffset, setTimeOffset] = useState(() => 0);
  const [revertOffset, setRevertOffset] = useState(false);

  // Set the time to now.
  const time = useTime();

  // FIXME: We need to fix issues with mobile rendering.
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TimeOffsetContext.Provider value={timeOffset}>
        <SearchBar time={time} cities={cities} setCities={setCities} />

        {/* Determine if we're on a mobile device */}

        {isMobile ? (
          <CityTimeBoard cities={cities} time={time} setCities={setCities} />
        ) : (
          <>
            <CityTimeBar cities={cities} time={time} setCities={setCities} />
            <Map cities={cities} time={time} />
            <SunLight
              time={time}
              setTimeOffset={setTimeOffset}
              revertOffset={revertOffset}
              setRevertOffset={setRevertOffset}
            />
            <TimeLine
              timeOffset={timeOffset}
              time={time}
              setRevertOffset={setRevertOffset}
            />
          </>
        )}
      </TimeOffsetContext.Provider>
    </ThemeProvider>
  );
}

// List of cities the user first sees when they first use the app (and if they don't modify things)
const initialCities = [
  {
    coordinates: [-118.24368, 34.05223],
    timeZone: "America/Los_Angeles",
    city: "Los Angeles",
    country: "United States",
  },
  {
    city: "New York",
    coordinates: [-74.0059728, 40.7127753],
    country: "United States",
    timeZone: "America/New_York",
  },
  {
    city: "São Paulo",
    coordinates: [-46.63330939999999, -23.5505199],
    country: "Brazil",
    timeZone: "America/Sao_Paulo",
  },
  {
    coordinates: [-0.118092, 51.509865],
    timeZone: "Europe/London",
    city: "London",
    country: "United Kingdom",
  },
  {
    city: "Paris",
    coordinates: [2.3522219, 48.856614],
    country: "France",
    timeZone: "Europe/Paris",
  },
  {
    city: "Dubai",
    coordinates: [55.2707828, 25.2048493],
    country: "United Arab Emirates",
    timeZone: "Asia/Dubai",
  },
  {
    city: "Tokyo",
    coordinates: [139.6503106, 35.6761919],
    country: "Japan",
    timeZone: "Asia/Tokyo",
  },
  {
    coordinates: [151.2099, -33.865143],
    timeZone: "Australia/Sydney",
    city: "Sydney",
    country: "Australia",
  },
];

export default App;
