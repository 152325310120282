import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import sunLightSummerSolstice from "./../images/sun-light-summer-solstice.svg";
import sunLightWinterSolstice from "./../images/sun-light-winter-solstice.svg";
import sunLightAutumnEquinox from "./../images/sun-light-autumn-equinox.svg";
import sunLightSpringEquinox from "./../images/sun-light-spring-equinox.svg";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    position: "relative",
    height: "100vh",
    display: "flex",
    minWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    userSelect: "none",
  },
  image: {
    height: "100vh",
    position: "relative",
    opacity: 0.15,
    zIndex: -100,
  },
  summerSolstice: {
    width: "171vh",
    transform: "scaleX(0.63)",
    margin: "0 -22vh",
  },
  winterSolstice: {
    width: "170vh",
    transform: "scaleX(0.62)",
    margin: "0 -20vh",
  },
  springEquinox: {
    width: "143vh",
    transform: "scaleX(0.57)",
    margin: "0 -7vh",
  },
  autumnEquinox: {
    width: "110vh",
    transform: "scaleX(0.69)",
    margin: "0 8vh",
  },
}));

function SunLight({ time, setTimeOffset, revertOffset, setRevertOffset }) {
  const classes = useStyles();
  const ref = useRef(null);
  const isInitialMount = useRef(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const season = getSeason(time, classes);
  const heightFactor = 1111.1111 / window.innerHeight;

  ////Revert to current light position////
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (revertOffset) {
      const initialPosition = getInitialPosition(ref, time, heightFactor);
      const minStep = 10;
      const step = Math.abs(initialPosition - scrollX) / 8;

      const interval = setInterval(() => {
        if (scrollX + minStep < initialPosition) {
          ref.current.scrollLeft += step;
          setScrollX(scrollX + step);
        } else if (scrollX - minStep > initialPosition) {
          ref.current.scrollLeft -= step;
          setScrollX(scrollX - step);
        } else {
          ref.current.scrollLeft = initialPosition;
          setScrollX(initialPosition);
          setRevertOffset(false);
        }
      }, 5);
      return () => {
        clearInterval(interval);
      };
    }
  }, [revertOffset, scrollX]);
  ////End - Revert to current light position////

  ////Position light on mount////
  useEffect(() => {
    const initialPosition = getInitialPosition(ref, time, heightFactor);
    ref.current.scrollLeft = initialPosition;
    setScrollX(initialPosition);
  }, []);
  ////End - Position light on mount////

  ////Make light dragscrollable////
  const onMouseDown = (e) => {
    setIsScrolling(true);
    setClientX(e.clientX);
  };

  const onMouseUp = () => {
    setIsScrolling(false);
  };

  const onMouseMove = (e) => {
    let newScrollX = scrollX - e.clientX + clientX;

    if (
      isScrolling &&
      newScrollX >= 0 &&
      newScrollX <= ref.current.scrollWidth - window.innerWidth
    ) {
      ref.current.scrollLeft = scrollX - e.clientX + clientX;
      setScrollX(newScrollX);
      setClientX(e.clientX);
    }
  };
  ////End - Make light dragscrollable////

  ////Update time offset////
  useEffect(() => {
    const interval = setInterval(() => {
      const timeOffset = Math.round(
        (getInitialPosition(ref, time, heightFactor) - scrollX) *
          heightFactor *
          60000
      );
      setTimeOffset(timeOffset);
    }, 5);
    return () => clearInterval(interval);
  }, [scrollX]);
  ////End - Update time offset////

  return (
    <div className={classes.outerContainer}>
      <div
        className={classes.container}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
      >
        <div>
          {[0, 1, 2, 3, 4, 5, 6].map((i) => (
            <img
              key={i}
              className={`${season.class} ${classes.image}`}
              src={season.lightImage}
              alt=""
              draggable="false"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function getInitialPosition(ref, time, heightFactor) {
  let gtmOffset = moment().format("Z");
  let plusOrMinus = gtmOffset.slice(0, 1);
  let hours = parseInt(gtmOffset.slice(1, 3));
  let minutes = parseInt(gtmOffset.slice(4, 6));
  gtmOffset = hours * 60 + minutes + 75;
  gtmOffset =
    plusOrMinus === "-"
      ? 675 - (hours * 60 + minutes)
      : 675 + (hours * 60 + minutes);

  let positionOffset =
    (gtmOffset - (time.getHours() * 60 + time.getMinutes())) / heightFactor;
  const centerPosition = ref.current.scrollWidth / 2 - window.innerWidth / 2;
  const initialPosition = centerPosition - positionOffset;

  return initialPosition;
}

function getSeason(time, classes) {
  let seasonClass;
  let seasonLightImage;
  let month = time.getMonth();

  switch (true) {
    case month >= 10 || month < 1:
      seasonClass = classes.winterSolstice;
      seasonLightImage = sunLightWinterSolstice;
      break;
    case month >= 1 && month < 4:
      seasonClass = classes.springEquinox;
      seasonLightImage = sunLightSpringEquinox;
      break;
    case month >= 4 && month < 7:
      seasonClass = classes.summerSolstice;
      seasonLightImage = sunLightSummerSolstice;
      break;
    case month >= 7 && month < 10:
      seasonClass = classes.autumnEquinox;
      seasonLightImage = sunLightAutumnEquinox;
      break;
    default:
      seasonClass = classes.springEquinox;
      seasonLightImage = sunLightSpringEquinox;
  }

  return { class: seasonClass, lightImage: seasonLightImage };
}

export default SunLight;
