import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import getDayOrNight from "./../helpers/getDayOrNight";
import { TimeOffsetContext } from "./../App";
import moment from "moment-timezone";

// const geoUrl =
  // "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

  const geoUrl = "/world-110m.json";

function MapMarker({ cityData, time, hide }) {
  const theme = useTheme();
  const { timeZone, city, country, coordinates } = cityData;
  const timeOffset = useContext(TimeOffsetContext);
  const timeWithOffset = new Date(time.getTime() - timeOffset);
  const formattedTime = moment(timeWithOffset).tz(timeZone).format("LT");
  const dayOrNight = getDayOrNight(
    timeWithOffset,
    coordinates[1],
    coordinates[0]
  );

  return (
    <Marker coordinates={coordinates}>
      <circle
        r={hide ? 1.5 : 2}
        style={hide ? { opacity: 0.65 } : {}}
        fill={
          dayOrNight === "day"
            ? theme.palette.day.main
            : theme.palette.night.main
        }
      />
      {!hide && (
        <>
          <text
            textAnchor="middle"
            y={-5}
            style={{
              fontFamily: theme.typography.fontFamily,
              fill: `${
                dayOrNight === "day"
                  ? theme.palette.day.main
                  : theme.palette.night.main
              }`,
              fontSize: "0.45rem",
            }}
          >
            {formattedTime}
          </text>
          <text
            textAnchor="middle"
            y={-17}
            style={{
              fontFamily: theme.typography.fontFamily,
              fill: `${theme.palette.text.primary}`,
              fontSize: "0.3rem",
            }}
          >
            {city}
          </text>
          <text
            textAnchor="middle"
            y={-12.5}
            style={{
              fontFamily: theme.typography.fontFamily,
              fill: `${theme.palette.text.primary}`,
              fontSize: "0.3rem",
            }}
          >
            {country}
          </text>
        </>
      )}
    </Marker>
  );
}

const useStyles = makeStyles({
  mapContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    margin: "auto",
    width: "100%",
    zIndex: -100,
    height: "100vh",
    overflow: "hidden",
  },
});

const Map = ({ cities, time }) => {
  const classes = useStyles();
  const theme = useTheme();

  let hideArr = [...cities].fill(false);

  cities.forEach((city1, i) => {
    cities.forEach((city2, j) => {
      if (
        j < i &&
        Math.abs(city1.coordinates[0] - city2.coordinates[0]) <= 25 &&
        Math.abs(city1.coordinates[1] - city2.coordinates[1]) <= 10 &&
        !hideArr[j]
      ) {
        hideArr[i] = true;
      }
    });
  });

  return (
    <div className={classes.mapContainer}>
      <ComposableMap
        style={{
          marginTop: 48,
        }}
        width={800}
        height={600 * (1.62 / (window.innerWidth / window.innerHeight))}
        projection="geoMercator"
        projectionConfig={{
          scale: (1.62 / (window.innerWidth / window.innerHeight)) * 100,
          rotate: [-12, 0, 0],
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={theme.palette.primary.main}
                style={{
                  default: { outline: "none" },
                  hover: { outline: "none" },
                  pressed: { outline: "none" },
                }}
              />
            ))
          }
        </Geographies>
        {cities.map((city, i) => {
          return (
            <MapMarker
              cityData={city}
              time={time}
              key={city.city + i}
              hide={hideArr[i]}
            />
          );
        })}
      </ComposableMap>
    </div>
  );
};

export default React.memo(Map);
