import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import tzlookup from "tz-lookup";
import moment from "moment-timezone";

const locationSearchInputStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    position: "relative",
    flexGrow: 1,
  },
  inputBase: {
    width: 200,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    fontSize: "0.8rem",
    paddingLeft: 8,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.contrastText,
    },
  },
  searchIcon: {
    fontSize: "1rem",
  },
  dropdownContainer: {
    minWidth: 200,
    position: "absolute",
    top: "calc(0.8rem + 20px)",
    left: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    padding: "8px 0",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
  suggestionItem: {
    fontSize: "0.8rem",
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    padding: "8px 8px",
  },
  suggestionItemActive: {
    fontSize: "0.8rem",
    backgroundColor: theme.palette.primary.light,
    cursor: "pointer",
    color: theme.palette.secondary.contrastText,
    padding: "8px 8px",
  },
}));

function LocationSearchInput({ cities, setCities }) {
  const classes = locationSearchInputStyles();
  const [city, setCity] = useState("");

  const handleChange = (city) => {
    setCity(city);
  };

  const handleSelect = (city) => {
    let results = geocodeByAddress(city);
    let latLng = results.then((results) => getLatLng(results[0]));

    Promise.all([results, latLng]).then(([results, { lat, lng }]) => {
      const address = results[0].address_components;

      if (
        !Boolean(
          ...cities.filter(
            (city) =>
              city.city === address[0].long_name &&
              city.country === address[address.length - 1].long_name
          )
        )
      ) {
        let newCity = {
          coordinates: [lng, lat],
          timeZone: tzlookup(lat, lng),
          city: address[0].long_name,
          country: address[address.length - 1].long_name,
        };
        setCities(cities.concat(newCity));
        localStorage.setItem("cities", JSON.stringify(cities.concat(newCity)));
      }
    });

    setCity("");
  };

  return (
    <PlacesAutocomplete
      value={city}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{ types: ["(cities)"] }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={classes.autocompleteContainer}>
          <InputBase
            {...getInputProps({})}
            autoFocus
            placeholder="Add city"
            className={classes.inputBase}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            }
          />

          {suggestions.length !== 0 && (
            <div className={classes.dropdownContainer}>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? classes.suggestionItemActive
                  : classes.suggestionItem;

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

const clockStyles = makeStyles((theme) => ({
  time: {
    fontSize: "1.3rem",
    fontWeight: 200,
    padding: "0 4px ",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
  },
}));

function Clock({ time }) {
  const classes = clockStyles();

  const formattedTime = moment(time).format("LT");

  return (
    <Typography className={classes.time} variant="h5">
      {formattedTime}
    </Typography>
  );
}

const searchBarStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  appBarMobile: {
    backgroundColor: theme.palette.background.default,
  },
}));

const SearchBar = ({ cities, setCities, time }) => {
  const classes = searchBarStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <AppBar
      position={isMobile ? "fixed" : "static"}
      className={`${isMobile ? classes.appBarMobile : classes.appBar}`}
    >
      <Toolbar variant="dense">
        <LocationSearchInput cities={cities} setCities={setCities} />
        <Clock time={time} />
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(SearchBar);
