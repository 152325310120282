import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const closeButtonStyles = makeStyles((theme) => ({
  button: {
    padding: "0 4px",
    color: `${theme.palette.text.primary}`,
    "&:hover": {
      color: `${theme.palette.text.light}`,
    },
  },
}));

const CloseButton = React.memo(({ setRevertOffset }) => {
  const classes = closeButtonStyles();
  const handleClick = () => {
    setRevertOffset(true);
  };

  return (
    <IconButton className={classes.button} onClick={handleClick}>
      <CloseIcon />
    </IconButton>
  );
});

const StyledSlider = withStyles((theme) => ({
  root: {
    color: `${theme.palette.moreHours.main}`,
    height: 12,
    padding: "12px 0 16px",
    bottom: 0,
    width: "100%",
    overflow: "hidden",
    marginBottom: 6,
    cursor: "default",
  },
  thumb: {
    display: "none",
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: `${theme.palette.text.primary}`,
  },
  markLabel: {
    top: "24px",
    color: `${theme.palette.text.primary}`,
  },
  markLabelActive: {
    color: `${theme.palette.text.primary}`,
  },
  mark: {
    opacity: 0.7,
    backgroundColor: `${theme.palette.text.primary}`,
    height: 4,
    width: 1,
    marginTop: -4,
  },
  markActive: {
    opacity: 1,
    backgroundColor: `${theme.palette.moreHours.main}`,
  },
}))(Slider);

const useStyles = makeStyles((theme) => ({
  container: { position: "absolute", bottom: 0, width: "100vw" },
  textBox: {
    minHeight: 32,
    left: "calc(50% - 40px)",
    textAlign: "center",
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 4,
    padding: "2px 0 2px 6px",
    color: `${theme.palette.moreHours.main}`,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  lessHours: { color: `${theme.palette.lessHours.main}` },
  lessHoursMarkActive: {
    backgroundColor: `${theme.palette.lessHours.main}`,
  },
}));

const TimeLine = ({ time, timeOffset, setRevertOffset }) => {
  const classes = useStyles();
  const timeWithOffset = new Date(time.getTime() - timeOffset);
  const sign = timeOffset < 0 ? "+ " : "- ";
  const hours = Math.floor(Math.abs(timeOffset / 60000) / 60);
  let minutes = Math.floor(Math.abs(timeOffset / 60000) % 60);
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return (
    <div className={classes.container}>
      {timeOffset ? (
        <Grid container justify="center" alignItems="center">
          <div className={classes.textBox}>
            <Typography className={`${timeOffset > 0 && classes.lessHours}`}>
              {sign + hours + ":" + minutes}
            </Typography>
            <CloseButton setRevertOffset={setRevertOffset} />
          </div>
        </Grid>
      ) : (
        ""
      )}
      <StyledSlider
        classes={{
          root: timeOffset > 0 && classes.lessHours,
          markActive: timeOffset > 0 && classes.lessHoursMarkActive,
        }}
        value={[50, (5 * timeOffset) / 60000 / 60 + 50]}
        marks={[
          {
            value: getMarkValue(timeWithOffset, 0),
            label: getMarkLabel(timeWithOffset, -10),
          },
          {
            value: getMarkValue(timeWithOffset, 5),
            label: getMarkLabel(timeWithOffset, -9),
          },
          {
            value: getMarkValue(timeWithOffset, 10),
            label: getMarkLabel(timeWithOffset, -8),
          },
          {
            value: getMarkValue(timeWithOffset, 15),
            label: getMarkLabel(timeWithOffset, -7),
          },
          {
            value: getMarkValue(timeWithOffset, 20),
            label: getMarkLabel(timeWithOffset, -6),
          },
          {
            value: getMarkValue(timeWithOffset, 25),
            label: getMarkLabel(timeWithOffset, -5),
          },
          {
            value: getMarkValue(timeWithOffset, 30),
            label: getMarkLabel(timeWithOffset, -4),
          },
          {
            value: getMarkValue(timeWithOffset, 35),
            label: getMarkLabel(timeWithOffset, -3),
          },
          {
            value: getMarkValue(timeWithOffset, 40),
            label: getMarkLabel(timeWithOffset, -2),
          },
          {
            value: getMarkValue(timeWithOffset, 45),
            label: getMarkLabel(timeWithOffset, -1),
          },
          {
            value: getMarkValue(timeWithOffset, 50),
            label: getMarkLabel(timeWithOffset, 0),
          },
          {
            value: getMarkValue(timeWithOffset, 55),
            label: getMarkLabel(timeWithOffset, 1),
          },
          {
            value: getMarkValue(timeWithOffset, 60),
            label: getMarkLabel(timeWithOffset, 2),
          },
          {
            value: getMarkValue(timeWithOffset, 65),
            label: getMarkLabel(timeWithOffset, 3),
          },
          {
            value: getMarkValue(timeWithOffset, 70),
            label: getMarkLabel(timeWithOffset, 4),
          },
          {
            value: getMarkValue(timeWithOffset, 75),
            label: getMarkLabel(timeWithOffset, 5),
          },
          {
            value: getMarkValue(timeWithOffset, 80),
            label: getMarkLabel(timeWithOffset, 6),
          },
          {
            value: getMarkValue(timeWithOffset, 85),
            label: getMarkLabel(timeWithOffset, 7),
          },
          {
            value: getMarkValue(timeWithOffset, 90),
            label: getMarkLabel(timeWithOffset, 8),
          },
          {
            value: getMarkValue(timeWithOffset, 95),
            label: getMarkLabel(timeWithOffset, 9),
          },
          {
            value: getMarkValue(timeWithOffset, 100),
            label: getMarkLabel(timeWithOffset, 10),
          },
        ]}
      />
    </div>
  );
};

function getMarkLabel(time, hours) {
  if (
    time.getHours() + hours === 12 ||
    time.getHours() + hours === 36 ||
    time.getHours() + hours === -12
  ) {
    return "12 PM";
  } else if ((time.getHours() + hours) % 12 === 0) {
    return "12 AM";
  } else if (time.getHours() + hours < 0) {
    return (time.getHours() + hours + 24) % 12;
  } else {
    return (time.getHours() + hours) % 12;
  }
}

function getMarkValue(time, percentage) {
  return percentage - (5 * time.getMinutes()) / 60;
}

export default React.memo(TimeLine);
