import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import getDayOrNight from "./../helpers/getDayOrNight";
import { TimeOffsetContext } from "./../App";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment-timezone";

const cityTypographyStyles = makeStyles((theme) => ({
  location: {
    textAlign: "center",
    lineHeight: "1.2",
    fontWeight: 200,
    fontSize: "0.8rem",
  },
  "@media (max-height: 640px)": {
    location: {
      fontSize: "0.5rem",
    },
  },
}));

const CityTypography = React.memo(({ city }) => {
  const classes = cityTypographyStyles();

  return (
    <Typography variant="caption" className={classes.location}>
      {city.city} <br />
      {city.country}
    </Typography>
  );
});

const closeButtonStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 2,
    color: `${theme.palette.text.primary}`,

    "&:hover": {
      color: `${theme.palette.text.light}`,
    },
  },
  icon: {
    fontSize: "1rem",
  },
}));

const CloseButton = ({ cities, setCities, index }) => {
  const classes = closeButtonStyles();

  const handleClick = () => {
    const citiesArr = cities.slice(0, index).concat(cities.slice(index + 1));
    setCities(citiesArr);
    localStorage.setItem("cities", JSON.stringify(citiesArr));
  };

  return (
    <IconButton className={classes.button} onClick={handleClick}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  );
};

const cityCardStyles = makeStyles((theme) => ({
  cityTimeContainer: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    padding: "16px 0 24px",
    width: "calc(50% - 4px)",
    zIndex: 100,
    margin: 2,
  },
  day: {
    borderBottom: `4px solid ${theme.palette.day.main}`,
  },
  night: {
    borderBottom: `4px solid ${theme.palette.night.main}`,
  },
  time: {
    fontWeight: 200,
    fontSize: "2.8rem",
  },
  timeCycle: {
    padding: "2px 0 8px",
    fontSize: "0.8rem",
  },
}));

function CityCard({ city, time, cities, setCities, index }) {
  const classes = cityCardStyles();
  const [showButton, setShowButton] = useState(() => false);
  const timeOffset = useContext(TimeOffsetContext);
  const timeWithOffset = new Date(time.getTime() - timeOffset);
  const preFormattedTime = moment(timeWithOffset)
    .tz(city.timeZone)
    .format("LT");

  const hoursAndMinutes = preFormattedTime.slice(
    0,
    preFormattedTime.length - 3
  );
  const ampm = preFormattedTime.slice(
    preFormattedTime.length - 2,
    preFormattedTime.length
  );
  const dayOrNight = getDayOrNight(
    timeWithOffset,
    city.coordinates[1],
    city.coordinates[0]
  );

  return (
    <Grid
      item
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={`${classes.cityTimeContainer} ${classes[dayOrNight]}`}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      {showButton && (
        <CloseButton cities={cities} setCities={setCities} index={index} />
      )}
      <Typography className={classes.time} variant="h4">
        {hoursAndMinutes}
      </Typography>
      <Typography variant="caption" className={classes.timeCycle}>
        {ampm}
      </Typography>
      <CityTypography city={city} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    marginTop: 48,
  },
}));

const CityTimeBoard = ({ cities, time, setCities }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.scrollContainer}>
        {cities.map((city, i) => (
          <CityCard
            city={city}
            time={time}
            key={`${city.city} + ${i}`}
            index={i}
            cities={cities}
            setCities={setCities}
          />
        ))}
      </Grid>
    </>
  );
};

export default React.memo(CityTimeBoard);
