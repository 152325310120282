import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Montserrat",
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    background: {
      default: "#2F2F2F",
    },
    primary: {
      light: "#6e6d72",
      main: "#434247",
      dark: "#1c1b20",
      contrastText: "#A5A4A4",
    },
    secondary: {
      main: "#E2C227",
      contrastText: "#fff",
    },
    text: {
      light: "#D2D1D1",
      primary: "#A5A4A4",
    },
    day: {
      main: "#E2C227",
    },
    night: {
      main: "#B671FF",
    },
    moreHours: {
      main: "#A3CEB0",
    },
    lessHours: {
      main: "#D8A5A4",
    },
    type: "light",
  },
});

export default theme;
