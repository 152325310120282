import { useState, useEffect } from "react";

export default function useTime() {
  const [time, setTime] = useState(new Date());

  // Basically change the time, whenever the user moves the 
  // slider, or 'shifts' the cities.
  useEffect(() => {
    function getTime() {
      let interval = (60 - new Date().getSeconds()) * 1000 + 5;
      setTime(new Date());
      setTimeout(getTime, interval);
    }
    getTime();
  }, []);

  return time;
}
